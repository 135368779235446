import { Link } from "gatsby"
import * as React from "react"
import Seo from "../components/SEO"

const NotFoundPage = () => (
    <>
        <Seo title="404" />
        <section className="container min-h-[90vh] flex flex-col justify-center items-center">
            <code className="font-bold text-7xl text-red animate-bounce">
                404
            </code>
            <h1 className="text-4xl font-bold">Page does not exist</h1>
            <Link to="/" className="underline hover:no-underline">
                Return to Home
            </Link>
        </section>
    </>
)

export default NotFoundPage
